@import "./../../styles/components/utilities";

.card {
  background-color: #fff;
  padding: 22.66px 14px;
  border-radius: 5px;
  margin: 12px 0;
  border: 1px solid rgba(61,61,61, .12);
}

.card--with-border {
  border: 1px solid rgba(177, 177, 177, .5);
}

.card-header {
  cursor: pointer;
}

.card-content {
  position: relative;
  margin-top: 32px;
  @include font-family-fallback("HelveticaNeue");
}

.card-content--compact {
  margin-top: 0;
}

.card-content__summary{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-content__summary .btn-primary--inset {
  margin-top: 30px;
}

.summary-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.summary-titles,
.summary-values {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summary-titles {
  padding-right: 20px;
}

.summary-group__img img {
  width: 48px;
}


.summary-group__title {
  font-weight: bold;
}

.summary-group__title,
.summary-group__value,
.summary-group__img {
  @include font-family-fallback('HelveticaNeue');
  margin: 5px 0;
  font-size: 15px;
  color: #393939;
}

.summary-values {
  @include font-family-fallback("Light");
  overflow: hidden;
  font-weight: 300;
}

@media (min-width: 600px) {
  .summary-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 55%;
  }
}



// new styles
.checkout-card {
  border-radius: 11px;
  background-color: #ffb632;
  padding: (35/16 + rem) (43/16 + rem);
  color: #fff;
}

.checkout-card--alternative, .checkout-card.editing-done {
  background-color: transparent;
  border: #ffb632 solid 1/16 + rem;
}

.checkout-card__title {
  font-family: 'MADEGentle';
  font-size: 28/16 + rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.checkout-card__title.editing-done {
  color: #ffb632;
}

.checkout-card.disabled {
  opacity: 0.48;
}

.checkout-card__input {
  outline: none;
  width: 100%;
  padding: (18/16 + rem) 0;
  margin: 0 0 (6/16 + rem);
  border: none;
  background: none;
  font-family: 'Helvetica';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ffffff;
  border-bottom: solid (1/16 + rem) #fff;
}

.checkout-card__input::placeholder {
  font-family: 'Helvetica';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.form-group__input--bt {
  background: none !important;
  color: white !important;
  border-bottom: solid (1/16 + rem) #fff !important;
}

.checkout-card__next{
  background-color: #ffffff;
  text-transform: uppercase;
  width: 162px;
  font-size: 18/16 + em;
  letter-spacing: 2px;
  color: #282828;
}

.checkout-card__edit{
  text-transform: uppercase;
  width: 150px;
  height: 44px;
  padding: 13px;
  font-size: 15px;
}

@media (max-width: 425px) {

  .checkout-card{
    padding-top: 33px;
    padding-bottom: 24px;
    padding-left: 28px;
    padding-right: 28px;
  }

  .checkout-card__title{
    font-size: 23/16 + em;
    line-height: 1.7;
  }

  .checkout-card__input{
    font-size: 14/16 + em;
    padding: 0.75rem 0;
  }
}
